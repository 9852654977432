import React from 'react';
import PropTypes from 'prop-types';

const CardIcon = ({
  width, height, className, children
}) =>
  (
    <div className={className}>
      <svg width={width} height={height} viewBox="0 0 58 34">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-420.000000, -678.000000)">
            <g id="Security-Number" transform="translate(335.000000, 645.000000)">
              <g id="Credit-card-CVV" transform="translate(84.000000, 32.000000)">
                <rect fill="#D9D9D9" x="1.5" y="1.5" width="57" height="33" rx="2" />
                { children }
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );

CardIcon.defaultProps = {
  width: '59px',
  height: '36px',
  className: ''
};

CardIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any
};

export default CardIcon;
