import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { fadeOut } from 'app/utils/helpers';
import { size, split } from 'lodash';

const { accountPageUrl, accountLogoutUrl, accountLoginUrl, iconAccountDefaultUrl, registerMobileUrl } = window.inlineGlobalConfig;

const AccountNameHeaderMobile = (props) => {
  const fadeSpeed = 200;
  const menuButton = document.querySelector('.js-menu');
  const menuOverlay = document.querySelector('.header__nav .menu__overlay');
  const menuActiveClass = 'is-active';

  const closeMenu = () => {
    menuButton.classList.remove(menuActiveClass);
    fadeOut(menuOverlay, fadeSpeed);
  };

  const syledHeaderMobile = {
    color: "white",
    fontWeight: "600",
    fontSize: "14px"
  };

  const nameUser = split(props.accountName,' ');

  return (<div>
    {props.accountIsLogged ?
      <div style={syledHeaderMobile} className="row">
        <a href={accountPageUrl} className="columns large-6">
          { props.urlImageProfile 
            ? <img className="img-profile" src={props.urlImageProfile} />
            : <img src={iconAccountDefaultUrl} />
          } <div>Bienvenido</div> 
            <div>{nameUser[0]}</div>
        </a>
        <a href={accountLogoutUrl} className="menu__account-tab-logout columns large-6">
          <FormattedMessage id="header.navigation.account.logoutLink" />
        </a>
      </div> :
      <div style={syledHeaderMobile}>
        <img src={iconAccountDefaultUrl} />{' '}
        <a href={`${accountLoginUrl}#/login`} onClick={closeMenu}>
          <FormattedMessage id="header.navigation.account.loginLink" />
        </a>{' | '}<a href={`${registerMobileUrl}`}>
          <FormattedMessage id="header.navigation.account.registrationLink" />
        </a>
      </div>
    }
  </div>
  );
}


AccountNameHeaderMobile.propTypes = {
  accountName: PropTypes.string,
  accountIsLogged: PropTypes.bool
};

export default AccountNameHeaderMobile;
