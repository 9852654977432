import { combineReducers } from 'redux';
import GlobalMessages from 'app/globalMessages/globalMessagesReducer';
import { totalEntries, miniCart } from 'app/orderPlacement/miniCart/miniCartReducer';
import { emptyCartTemplate } from 'app/orderPlacement/cart/components/emptyCart/emptyCartReducer';
import { isCartFetching, cart, hasErrors } from 'app/orderPlacement/cart/components/cartList/cartListReducer';
import { cachedItemBeforeUpdate } from 'app/commonComponents/cartItem/cartItemReducer';
import { AppliedCouponState } from 'app/orderPlacement/cart/components/cartSummary/appliedCoupon/appliedCouponReducer';
import recommendedProductsCarouselReducer from '../cmscomponents/carousel/recommendedProductsCarousel/recommendedProductsCarouselReducer';
import { currentStore } from 'app/paymentIcons/paymentIconsReducer';
import { ATBState, QtyFormUpdate } from 'app/commonComponents/addToBag/addToBagReducer';
import getsocialNetworkInstructiveInfo from 'app/orderPlacement/productDetailsPage/productExtraDetails/productExtraDetailsReducer';
import { detailsPageReducer, outOfStock, loadedProduct } from 'app/orderPlacement/productDetailsPage/productDetailsPageReducer';
import recentlyViewedReducer from '../orderPlacement/productDetailsPage/recentlyViewedCarousel/recentlyViewedCarouselReducer';

export default combineReducers({
  detailsPageReducer,
  outOfStock,
  isCartFetching,
  loadedProduct,
  cart,
  emptyCartTemplate,
  cachedItemBeforeUpdate,
  totalEntries,
  GlobalMessages,
  ATBState,
  QtyFormUpdate,
  getsocialNetworkInstructiveInfo,
  currentStore,
  hasErrors,
  AppliedCouponState,
  miniCart,
  recommendedProductsCarouselReducer,
  recentlyViewedReducer,
});
