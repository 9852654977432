import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

const { enableCouponDiscount } = window.inlineCheckout;

const mapPromosDescription = (orderPromotions, productPromotions) => {
  const promoDescs = new Set();
  if (!isEmpty(orderPromotions)) {
    orderPromotions.forEach((orderPromo) => { promoDescs.add(orderPromo.description); });
  }
  if (!isEmpty(productPromotions)) {
    productPromotions.forEach((productPromo) => { productPromo.consumedEntries.forEach(() => { promoDescs.add(productPromo.description); }); });
  }
  return Array.from(promoDescs);
};

const isGreaterThanZero = (currency = '') => Number(currency.replace(/[^0-9.-]+/g, '')) > 0;
const CURRENCY_DECIMAL = 2;

const orderSummaryCalculations = (props) => {
  const {
    subTotal,
    deliveryMode,
    totalPrice,
    totalTax,
    showTaxes,
    shouldHandleTaxes,
    className,
    totalDiscounts,
    appliedCouponCode,
    showRemoveCouponCTA,
    onCouponRemoveClick,
    appliedOrderPromotions,
    appliedProductPromotions,
    shippingCostRefunded,
    refundedAmount,
    refundedTax,
    subTotalWithoutDiscounts,
    productDiscounts,
    commercialExpenses
  } = props;
  const promosDescription = mapPromosDescription(appliedOrderPromotions, appliedProductPromotions);
  return (
    <div className={`cart-order-summary__calculations ${className}`}>
      <div className="row">
        <div className="columns small-7">
          <div className="cart-order-summary__subtotal">
            <FormattedMessage id="order.summary.order.subtotal" description="Item total" />
          </div>
        </div>
        <div className="columns small-5 text-right">
          <div className="cart-order-summary__subtotal-value">
            <span>{subTotalWithoutDiscounts}</span>
          </div>
        </div>
      </div>
      {
        isGreaterThanZero(productDiscounts) &&
        <div className="row">
          <div className="columns small-7">
            <div className="cart-order-summary__shipping">
              <FormattedMessage id="order.summary.product.discounts" />
            </div>
          </div>
          <div className="columns small-5 text-right">
            <div className="cart-order-summary__shipping-value">
              <span>-{productDiscounts}</span>
            </div>
          </div>
        </div>
      }
      {
        appliedCouponCode !== '' && !isEmpty(appliedCouponCode) && enableCouponDiscount &&
          <div className="row">
            <div className="columns small-7">
              <div className="cart-order-summary__discounts">
                <span className="cart-order-summary__promo-code">{appliedCouponCode}</span>
                {
                  showRemoveCouponCTA &&
                    <button className="button button--link cart-order-summary__remove-btn" onClick={() => onCouponRemoveClick(appliedCouponCode)}>
                      <FormattedMessage id="cart.operations.remove" description="Remove" />
                    </button>
                }
              </div>
            </div>
            {
              (totalDiscounts !== '') &&
                <div className="columns small-5 text-right">
                  <div className="cart-order-summary__discounts-value">
                    <span>-{totalDiscounts}</span>
                  </div>
                </div>
            }
          </div>
      }
      {
        isGreaterThanZero(subTotal) &&
        subTotalWithoutDiscounts !== subTotal &&
        <div>
          <hr />
          <div className="row">
            <div className="columns small-7">
              <div className="cart-order-summary__shipping-without-discounts">
                <FormattedMessage id="order.summary.subtotal.without.discounts" />
              </div>
            </div>
            <div className="columns small-5 text-right">
              <div className="cart-order-summary__shipping-value-without-discounts">
                <span>{subTotal}</span>
              </div>
            </div>
          </div>
        </div>
      }
      <div className="row">
        <div className="columns small-7">
          <div className="cart-order-summary__shipping">
            <FormattedMessage id="order.summary.delivery.title" />
          </div>
        </div>
        <div className="columns small-5 text-right">
          <div className="cart-order-summary__shipping-value">
            <span>{ (deliveryMode.price === 'GRATIS' || deliveryMode.price === undefined) ? <FormattedMessage id="security.ui.checkout.resumen.free.shipping" /> : deliveryMode.price }</span>
          </div>
        </div>
      </div>
      {
        commercialExpenses && commercialExpenses.value > 0 &&
        <div className="row">
          <div className="columns small-7">
            <div className="cart-order-summary__shipping">
              <FormattedMessage id="order.summary.commercial.expenses" />
            </div>
          </div>
          <div className="columns small-5 text-right">
            <div className="cart-order-summary__shipping-value">
              <span>+{commercialExpenses.symbol}{commercialExpenses.value.toFixed(CURRENCY_DECIMAL)}</span>
            </div>
          </div>
        </div>
      }
      {
        shippingCostRefunded &&
        <div className="row">
          <div className="columns small-7">
            <div className="cart-order-summary__shipping">
              <FormattedMessage id="order.summary.order.refund.shipping" />
            </div>
          </div>
          <div className="columns small-5 text-right">
            <div className="cart-order-summary__shipping-value">
              <span>{deliveryMode.price}</span>
            </div>
          </div>
        </div>
      }
      {
        !isEmpty(promosDescription) &&
          <div className="row display-none">
            <div className="cart-order-summary__discounts columns large-7 small-3">
              {promosDescription.map((promoDesc) => <div key={promoDesc}> {promoDesc} </div>)}
            </div>
            {
              (appliedCouponCode === '' && totalDiscounts !== '') &&
                <div className="cart-order-summary__discounts-value-centered columns small-3 text-right">
                  <span>-{totalDiscounts}</span>
                </div>
            }
          </div>
      }
      {
        (shouldHandleTaxes && showTaxes) &&
          <div className="row">
            <div className="columns small-7">
              <div className="cart-order-summary__shipping">
                <FormattedMessage id="order.summary.tax" description="Taxes" />
              </div>
            </div>
            <div className="columns small-5 text-right">
              <div className="cart-order-summary__shipping-value">
                <span>{totalTax}</span>
              </div>
            </div>
          </div>
      }
      <hr />
      <div className="row">
        <div className="columns small-8">
          <div className="cart-order-summary__total">
            <FormattedMessage id="order.summary.order.total" description="Total" />
          </div>
        </div>

        <div className="columns small-4 text-right">
          <div className="cart-order-summary__total-value">
            <span>{totalPrice}</span>
          </div>
        </div>
      </div>
      {
        (shouldHandleTaxes && !showTaxes) &&
          <div className="row">
            <div className="columns small-12">
              <div className="cart-order-summary__tax-info">
                <FormattedMessage id="order.summary.tax.included" description="Tax included" />
              </div>
            </div>
          </div>
      }
      {
        refundedAmount && refundedAmount.match(/[1-9]+/g) &&
        <div className="row cart-order-summary__total--refund">
          <div className="columns small-8">
            <div className="cart-order-summary__total">
              <FormattedMessage id="order.summary.order.refund.total" description="Refund Total" />
            </div>
          </div>
          <div className="columns small-4 text-right">
            <div className="cart-order-summary__total-value">
              <span>{refundedAmount}</span>
            </div>
          </div>
        </div>
      }
      {
        refundedTax && refundedTax.match(/[1-9]+/g) &&
        <div className="row">
          {
            showTaxes ?
              <div>
                <div className="columns small-7">
                  <div className="cart-order-summary__tax-info">
                    <FormattedMessage id="order.summary.tax" description="Tax" />
                  </div>
                </div>
                <div className="columns small-5 text-right">
                  <div className="cart-order-summary__tax-info">
                    <span>{refundedTax}</span>
                  </div>
                </div>
              </div> :
              <div className="columns small-7">
                <div className="cart-order-summary__tax-info">
                  <FormattedMessage id="order.summary.tax.included" description="Tax included" />
                </div>
              </div>
          }
        </div>
      }
    </div>
  );
};

orderSummaryCalculations.propTypes = {
  subTotalWithoutDiscounts: PropTypes.string,
  productDiscounts: PropTypes.string,
  commercialExpenses: PropTypes.shape({
    value: PropTypes.number,
    symbol: PropTypes.string
  }),
  subTotal: PropTypes.string,
  deliveryMode: PropTypes.object,
  totalDiscounts: PropTypes.string,
  totalPrice: PropTypes.string,
  appliedCouponCode: PropTypes.string,
  onCouponRemoveClick: PropTypes.func,
  totalTax: PropTypes.string,
  showTaxes: PropTypes.bool,
  shouldHandleTaxes: PropTypes.bool,
  showRemoveCouponCTA: PropTypes.bool,
  className: PropTypes.string,
  appliedOrderPromotions: PropTypes.array,
  appliedProductPromotions: PropTypes.array,
  refundedAmount: PropTypes.string,
  refundedTax: PropTypes.string,
  shippingCostRefunded: PropTypes.bool
};

orderSummaryCalculations.defaultProps = {
  className: '',
  shouldHandleTaxes: false,
  showRemoveCouponCTA: false
};

export default orderSummaryCalculations;
