import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import customEvents from 'app/customEvents/customEventsMiddleware';
import orderPlacementRootReducer from 'app/orderPlacement/orderPlacementReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  orderPlacementRootReducer,
  composeEnhancers(
    applyMiddleware(thunk, customEvents)
  )
);

export default store;
