import { RECEIVE_ADD_CART_ITEM } from 'app/commonComponents/addToBag/addToBagConstants';
import { RECEIVE_CART_ITEMS } from 'app/orderPlacement/cart/components/cartList/cartListConstants';
import { RECEIVE_MINI_CART, MINI_CART_IS_FETCHING, MINI_CART_IS_FETCHED, RECEIVE_MINI_CART_ID } from './miniCartConstants';
import { FAKE_REQUEST_UPDATE_MINICART } from 'app/productConfirmation/productConfirmationConstants';

export const totalEntries = (state = 0, action) => {
  switch (action.type) {
    case RECEIVE_ADD_CART_ITEM:
    case RECEIVE_CART_ITEMS:
    case RECEIVE_MINI_CART:
      return action.totalEntries;
    case FAKE_REQUEST_UPDATE_MINICART: {
      const newState = {
        ...state,
        fakeUpdateMinicart: action.fakeUpdateMinicart
      };
      return newState;
    }
    default:
      return state;
  }
};

export const miniCart = (state = {
  cartId: null,
  isMiniCartFetching: false,
  isMiniCartFetched: false
}, action) => {
  switch (action.type) {
    case MINI_CART_IS_FETCHING:
      return {
        ...state,
        isMiniCartFetching: true
      };
    case RECEIVE_MINI_CART_ID:
      return {
        ...state,
        cartId: action.cartId
      };
    case MINI_CART_IS_FETCHED:
      return {
        ...state,
        isMiniCartFetching: false,
        isMiniCartFetched: true
      };
    default:
      return state;
  }
};
