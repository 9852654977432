import { EMPTY_CART } from './emptyCartConstants';

export const emptyCartTemplate = (state = '', action) => {
  switch (action.type) {
    case EMPTY_CART:
      return action.template;
    default:
      return state;
  }
};
