import React from 'react';
import PropTypes from 'prop-types';
import Icon from './paymentIcon/paymentIcon';

const paymentIcons = ({ icons, className }) => (
  <div className={`payment-icons ${className}`}>
    {icons.map((iconName) => (
      <Icon key={iconName} iconName={iconName} />
    ))}

    {/* <div className="icons-payment-checkout">
      <img
        className="payment-icon"
        src="/assets/images/cc-icons/amex.svg"
        alt="icon american expresss"
      />
    </div> */}

    <div className="icons-payment-checkout">
      <img
        className="payment-icon special-icon"
        src="/assets/images/cc-icons/cashOnDelivery.svg"
        alt="icon cash on delivery"
      />
    </div>
  </div>
);

paymentIcons.defaultProps = {
  icons: [],
  className: '',
};

paymentIcons.propTypes = {
  className: PropTypes.string,
  icons: PropTypes.array,
};

export default paymentIcons;
