import { EMPTY_CART } from './emptyCartConstants';
import { ajaxRequest } from 'app/utils/helpers';

const { emptyCartUrl } = window.inlineCartConfiguration;

export const emptyCart = (template) => ({
  type: EMPTY_CART,
  template
});

export const fetchEmptyCart = () => (dispatch) => {
  ajaxRequest('GET', emptyCartUrl).then(
    html => {
      dispatch(emptyCart(html));
    },
    () => {
      dispatch(emptyCart(''));
    }
  );
};

const actions = {
  fetchEmptyCart
};

export default actions;

