import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const { accountLogoutUrl } = window.inlineGlobalConfig;

export const AccountNameTitle = (props) => (
  <span>
    <span>{props.accountName}</span>
    <span className="page-title__sub">
      <a href={accountLogoutUrl} className="page-title__logout-link">
        <FormattedMessage id="header.navigation.account.logoutLink" />
      </a>
    </span>
  </span>
);

AccountNameTitle.propTypes = {
  accountName: PropTypes.string.isRequired
};

export default AccountNameTitle;
