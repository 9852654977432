import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import LoggedUserInfo from './loggedUserInfoReducer';
import customEvents from 'app/customEvents/customEventsMiddleware';

const loggedUserInfoReducers = combineReducers({ LoggedUserInfo });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  loggedUserInfoReducers,
  composeEnhancers(
    applyMiddleware(thunk, customEvents)
  )
);

export default store;
