import {
  REQUEST_USER_INFO,
  RECEIVE_USER_INFO,
  UPDATE_ACCOUNT_NAME,
  UPDATE_ACCOUNT_LOGGED_STATUS,
  UPDATE_CSAGENT_INFO,
  UPDATE_CSAGENT_LOGGED_STATUS
} from './loggedUserInfoConstants';

const initialState = {
  isFetching: false,

  accountName: '',
  accountIsLogged: false,

  csAgentUsername: '',
  csAgentIsLogged: false,
  csAgentCartId: null,
  urlImageProfile: ''
};

const loggedUserInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_USER_INFO:
      return {
        ...state,
        isFetching: true
      };
    case RECEIVE_USER_INFO:
      return {
        ...state,
        isFetching: false
      };
    case UPDATE_ACCOUNT_NAME:
      return {
        ...state,
        accountName: action.accountName,
        urlImageProfile: action.urlImageProfile
      };
    case UPDATE_CSAGENT_INFO:
      return {
        ...state,
        csAgentUsername: action.csAgentUsername,
        csAgentCartId: action.csAgentCartId
      };
    case UPDATE_ACCOUNT_LOGGED_STATUS:
      return {
        ...state,
        accountIsLogged: action.accountIsLogged
      };
    case UPDATE_CSAGENT_LOGGED_STATUS:
      return {
        ...state,
        csAgentIsLogged: action.csAgentIsLogged
      };
    default:
      return state;
  }
};

export default loggedUserInfoReducer;
