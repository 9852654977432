const { cartTotalItemsStrategy } = window.inlineGlobalConfig;

export const removeCartBanner = () => {
  const banners = document.querySelectorAll('.cart__media-banner');
  [].forEach.call(banners, (banner) => {
    banner.parentNode.removeChild(banner);
  });
};

export const cartTotalItems = (json) => {
  switch (cartTotalItemsStrategy) {
    case 'sku':
      return json.totalItems;
    default:
      return json.entries ? json.entries.length : 0;
  }
};

export const shouldIncrementTotalEntriesNumber = (json) => {
  switch (cartTotalItemsStrategy) {
    case 'sku':
      return !json.errors && json.quantityAdded !== 0;
    default:
      return !json.errors && json.quantityAdded !== 0 && json.entry.quantity === 1;
  }
};
