import React, { Component } from 'react';
import PropTypes from 'prop-types';

const { baseCheckoutUrl, baseCartUrl } = window.inlineCartConfiguration;

class CartIcon extends Component {
  componentDidMount() {
    const {
      fetchMiniCartThenCart,
      fetchCart
    } = this.props;

    fetchMiniCartThenCart();
    fetchCart();
  }

  render() {
    return (
      <a href={baseCartUrl} className="nav-btn nav-btn__bag" rel="nofollow">
        {
          this.props.totalEntries === 0 ?
          <div className="nav-btn__icon-wrapper">
            <img src="https://storage.googleapis.com/betterware-production-media/content/home/Iconos/icono-carrito.svg" />
          </div> :
            <div className="nav-btn__icon-wrapper">
              <img src="https://storage.googleapis.com/betterware-production-media/content/home/Iconos/icono-carrito.svg" />
              <span className="nav-btn__icon counter"><div className="found-count">{this.props.totalEntries}</div></span>
            </div>
        }
        <p className="nav-btn__label">
          <span className="nav-btn__label-inner"/>
        </p>
      </a>
    );
  }
}

CartIcon.propTypes = {
  totalEntries: PropTypes.number.isRequired,
  fetchMiniCartThenCart: PropTypes.func.isRequired,
  fetchCart: PropTypes.func.isRequired
};

export default CartIcon;
