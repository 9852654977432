import { GET_CURRENT_STORE } from './paymentIconsConstants';

export const currentStore = (state = {
  supportedPaymentModes: []
}, action) => {
  switch (action.type) {
    case GET_CURRENT_STORE:
      return Object.assign({}, state, action.currentStore);
    default:
      return state;
  }
};
