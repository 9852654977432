import React from 'react';
import PropTypes from 'prop-types';
import CardIcon from '../cardIcon/cardIcon';

const paymentIcon = ({
  iconName, className, hasDefault, ...otherProps
}) => {
  if (!iconName && hasDefault) {
    return (
      <CardIcon
        width="100%"
        height="100%"
        className={className}
        {...otherProps}
      />
    );
  }

  if (!iconName && !hasDefault) {
    return null;
  }

  return (
    <div className="icons-payment-checkout">
      { iconName === 'visa' ?
        <img
          className={`payment-icon ${className}`}
          src="https://storage.googleapis.com/betterware-production-media/content/home/Iconos/visa.svg"
          alt={`icon ${iconName}`}
          {...otherProps}
        /> :
        <img
          className={`payment-icon ${className}`}
          src={`/assets/images/cc-icons/${iconName}.svg`}
          alt={`icon ${iconName}`}
          {...otherProps}
        />
      }
    </div>
  );
};

paymentIcon.defaultProps = {
  className: ''
};

paymentIcon.propTypes = {
  iconName: PropTypes.string,
  className: PropTypes.string,
  hasDefault: PropTypes.bool
};

export default paymentIcon;
