import {
  STATUS_CODES,
  DEFAULT_QTY_FORM_STATE,
  UPDATE_ADD_TO_CART_QTY_FORM,
  MINIMUM_QTY_REACHED,
  MAXIMUM_QTY_REACHED
} from './addToBagConstants';

import { RECEIVE_CART_ITEMS } from 'app/orderPlacement/cart/components/cartList/cartListConstants';
import { RECEIVE_PRODUCTS } from 'app/products/components/productList/productListConstants';
import { DETAILS_LOADED } from 'app/orderPlacement/productDetailsPage/productDetailsPageConstants';

export const ATBState = (state = [], action) => {
  switch (action.type) {
    case DETAILS_LOADED: {
      return {
        ...state,
        [action.data.skus[0].id]: STATUS_CODES.ADD_TO_BAG_READY
      };
    }
    case RECEIVE_PRODUCTS: {
      const defaultATBStates = {};
      action.products.forEach(product => {
        defaultATBStates[`V${product.id}`] = STATUS_CODES.ADD_TO_BAG_READY;
      });

      return {
        ...defaultATBStates
      };
    }
    case STATUS_CODES.ADDED_TO_BAG: {
      return {
        ...state,
        [action.entry.product.code]: action.type
      };
    }
    case STATUS_CODES.ADD_TO_BAG_READY:
    case STATUS_CODES.BUY_NOW_READY:
    case STATUS_CODES.ADDING_TO_BAG:
      return {
        ...state,
        [action.sku]: action.type
      };
    case STATUS_CODES.BUY_NOW: {
      return {
        ...state,
        [action.entry.product.code]: action.type
      };
    }
    case STATUS_CODES.BUYING_NOW:
      return {
        ...state,
        [action.sku]: action.type
      };
    case STATUS_CODES.OUT_OF_STOCK:
    case STATUS_CODES.ERROR: {
      return {
        ...state,
        [action.entry.product.code]: action.type
      };
    }
    default:
      return state;
  }
};

export const QtyFormUpdate = (state = [], action) => {
  switch (action.type) {
    case DETAILS_LOADED: {
      const sku = action.data.skus[0].id;
      return {
        ...state,
        [sku]: {
          ...DEFAULT_QTY_FORM_STATE,
          actualProductSKU: sku,
          addToCartMax: parseInt(action.data.addToCartMax, 10)
        }
      };
    }
    case RECEIVE_PRODUCTS: {
      const newState = {
        ...state
      };
      action.products.forEach(product => {
        const sku = `V${product.id}`;
        const skuState = (state[sku]) ? state[sku] : DEFAULT_QTY_FORM_STATE;

        newState[`V${product.id}`] = {
          ...skuState,
          actualProductSKU: `V${product.id}`
        };
      });

      return newState;
    }
    case RECEIVE_CART_ITEMS: {
      const newState = {
        ...state
      };

      action.entries
        .forEach(entry => {
          const skuState = (state[entry.id]) ? state[entry.id] : DEFAULT_QTY_FORM_STATE;
          newState[entry.id] = {
            ...skuState,
            addToCartMax: parseInt(entry.addToCartMax, 10) || 0
          };
        });

      return newState;
    }
    case STATUS_CODES.ADDED_TO_BAG: {
      return {
        ...state,
        [action.entry.product.code]: {
          ...state[action.entry.product.code],
          addToCartMax: parseInt(action.entry.product.addToCartMax, 10),
          actualQty: 1,
        }
      };
    }
    case UPDATE_ADD_TO_CART_QTY_FORM: {
      return {
        ...state,
        [action.sku]: {
          ...state[action.sku],
          actualQty: action.data,
          minimumQtyReached: false,
          maximumQtyReached: false
        }
      };
    }
    case MINIMUM_QTY_REACHED: {
      return {
        ...state,
        [action.sku]: {
          ...state[action.sku],
          minimumQtyReached: true
        }
      };
    }
    case MAXIMUM_QTY_REACHED: {
      return {
        ...state,
        [action.sku]: {
          ...state[action.sku],
          maximumQtyReached: true
        }
      };
    }
    default:
      return state;
  }
};
