import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { usernamePadding } from 'app/utils/helpers';
import { split } from 'lodash';

const { accountPageUrl, accountLogoutUrl, accountLoginUrl, iconAccountDefaultUrl } = window.inlineGlobalConfig;

class AccountNameHeader extends Component {
  componentDidMount() {
    usernamePadding();
  }

  render() {
    const { accountName, accountIsLogged, urlImageProfile } = this.props;
    const directLoginUrl = `${window.location.origin}/mx/es/login/auth#/login`;
    const nameUser = split(accountName,' ');

    const syledIconProfile = {
      paddingRight: "2.5rem"
    };

    return (
      <div>
        {accountIsLogged ?
          <div>
            <a className="nav-btn__icon nav-btn__icon--account" href={accountPageUrl}>
              { urlImageProfile 
                ? <img className="img-profile" src={urlImageProfile} />
                : <img src={iconAccountDefaultUrl} />
              }
            </a>
            <div className="nav-btn__label name-profile">
              <div>
                <div className="nav-btn__label-box-2 dropdown">
                  <button className="dropbtn">Bienvenido <div>{nameUser[0]}</div></button>
                  <div className="dropdown-content">
                    <a href={accountPageUrl}>
                      <span className="nav__account-name">
                        Mi Perfil
                      </span>
                    </a>
                    <a id="logout-link" href={accountLogoutUrl}>
                      <FormattedMessage id="header.navigation.account.logoutLink" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> :
          <div style={syledIconProfile}>
            <a className="nav-btn__icon nav-btn__icon--account" href={directLoginUrl}>
              <span className="hidden-for-small-up">
                <FormattedMessage id="header.navigation.account.label" />
              </span>
              <img src={iconAccountDefaultUrl} />
            </a>
          </div>
        }
      </div>
    );
  }
}

AccountNameHeader.propTypes = {
  accountName: PropTypes.string,
  accountIsLogged: PropTypes.bool
};

export default AccountNameHeader;
