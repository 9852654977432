import { connect } from 'react-redux';
import cartIcon from './cartIcon';
import { fetchMiniCartThenCart } from "../miniCartActions";
import { fetchCart } from "../../cart/components/cartList/cartListActions";

const mapStateToProps = (state) => {
  const totalEntries = (state.totalEntries.fakeUpdateMinicart) ? state.totalEntries.fakeUpdateMinicart : state.cart.totalItems;
  return {
    totalEntries: totalEntries
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchMiniCartThenCart: () => {
    dispatch(fetchMiniCartThenCart());
  },
  fetchCart: () => {
    dispatch(fetchCart());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(cartIcon);
