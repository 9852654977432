import { DETAILS_LOADED } from './productDetailsPageConstants';

const detailsPageReducerDefault = {
  name: '',
  wasPrices: '',
  prices: '',
  sku: ''
};

export const outOfStock = (state = false, action) => {
  switch (action.type) {
    case DETAILS_LOADED:
      return !action.data.hasStock;
    default:
      return state;
  }
};

export const loadedProduct = (state = {}, action) => {
  switch (action.type) {
    case DETAILS_LOADED:
      return {
        ...action.data,
        sku: action.data.skus[0].id
      };
    default:
      return state;
  }
};

export const detailsPageReducer = (state = detailsPageReducerDefault, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
