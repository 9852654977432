import { RECEIVE_MINI_CART, MINI_CART_IS_FETCHING, MINI_CART_IS_FETCHED, RECEIVE_MINI_CART_ID } from './miniCartConstants';
import { fetchCart } from 'app/orderPlacement/cart/components/cartList/cartListActions';
import { ajaxRequest } from 'app/utils/helpers';
import { cartTotalItems } from '../cart/cartUtils';

const { miniCartUrl } = window.inlineCartConfiguration;
const { pageType } = window.inlineGlobalConfig;

export const receiveMiniCart = (json = {
  entries: [],
  totalEntries: 0
}) => ({
  type: RECEIVE_MINI_CART,
  entries: json.entries,
  totalEntries: json.totalEntries,
  totalItems: json.totalItems
});

export const fetchMiniCart = () => (dispatch) => ajaxRequest('GET', miniCartUrl).then(
  (json) => {
    dispatch({ type: RECEIVE_MINI_CART_ID, cartId: json.id });
    dispatch(receiveMiniCart({
      entries: json.entries,
      totalEntries: cartTotalItems(json)
    }));
  },
  () => dispatch(receiveMiniCart())
);

export const fetchMiniCartThenCart = () => (dispatch, getState) => {
  const { isMiniCartFetching, isMiniCartFetched } = getState().miniCart;
  const initialFetchCart = true;

  if (!isMiniCartFetching && !isMiniCartFetched) {
    dispatch({ type: MINI_CART_IS_FETCHING });

    if (pageType === 'bag') {
      return dispatch(fetchMiniCart())
        .then(() => dispatch({ type: MINI_CART_IS_FETCHED, cartId: getState().miniCart.cartId }))
        .then(() => dispatch(fetchCart(initialFetchCart)));
    }

    return dispatch(fetchMiniCart())
      .then(() => dispatch({ type: MINI_CART_IS_FETCHED, cartId: getState().miniCart.cartId }));
  }

  return Promise.resolve(true);
};
