import React from 'react';
import { FormattedMessage } from 'react-intl';

const orderSummaryHeader = () => (
  <div className="row">
    <div className="columns small-12">
      <div className="cart-order-summary__title">
        <h3>
          <FormattedMessage id="cart.summary.header" description="Summary title" />
        </h3>
      </div>
    </div>
  </div>
);

export default orderSummaryHeader;
