/* eslint-disable no-magic-numbers */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import parseHTML from 'html-react-parser';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ERROR_LIST, CART_ITEM_ALERT_CLASS, CART_ITEM_NO_STOCK_ALERT_CLASS } from 'app/orderPlacement/cart/components/cartList/cartListConstants';
import { PRIMARY } from './cartItemConstants';
import { deleteCart } from '../../checkout/checkoutActions';
import { cartFinishedUpdate } from './cartItemActions';

export const getImageSrcByType = (images, imageType) => (
  images && images.filter(image => image.imageType === imageType)[0].url
);
export const getErrors = (cartModification) => {
  const hasModification = !!(cartModification && cartModification.statusCode);
  const hasError = (hasModification && ERROR_LIST.indexOf(cartModification.statusCode) !== -1);
  const hasNoStockError = (hasError && (cartModification.stock === 0 || cartModification.statusCode === ERROR_LIST[1]));
  return { hasError, hasNoStockError };
};

export const getAlertClass = (hasNoStockError) => (
  hasNoStockError ? CART_ITEM_NO_STOCK_ALERT_CLASS : CART_ITEM_ALERT_CLASS
);

export const getRawValue = (encodedMsg) => (
  <span dangerouslySetInnerHTML={{ __html: encodedMsg }} />
);

// Components
import Img from '../../productListComponent/ProductListComponentImage';
import ItemCounter from './itemCounter';

// Elements
import { Wrapper, Delete, Column, Title, RegularPrice, OfferPrice, TotalPrice } from './cartItem.styled';

class CartItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: getErrors(props.product.productModification),
      cartModificationsMessage: '',
      mainSize: {
        mm: 640,
        m: 320,
        s: 240,
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cartModifications && nextProps.cartModifications.length) {
      this.setState({
        ...this.state,
        cartModificationsMessage: nextProps.cartModifications[0].statusCode
      });
    }
    const { resetErrorFlag } = this.props;
    const errors = getErrors(nextProps.product.productModification);
    if (errors.hasError !== this.state.errors.hasError) {
      resetErrorFlag(errors.hasError);
      this.setState({
        errors
      });
    }
  }

  delete(e) {
    const {
      totalEntries,
      product,
      onCartItemRemove,
      cachedItemBeforeUpdate,
      onDeleteCart
    } = this.props;

    const isAnimateOnRemoveOnly = cachedItemBeforeUpdate.position === product.position;

    if (totalEntries > 1) {
      onCartItemRemove(e.target, product.position, isAnimateOnRemoveOnly);
    } else {
      onDeleteCart();
    }
  }

  /**
   * Parse any given value into a moneyFormat in the 'es-MX' lang
   *
   * @param {String|Number} value         Value to be formatted
   * @param {object} options              Format options
   * @param {Number|2} options.decimals   Number of decimals to be shown/filled
   * @param {String|''} options.symbol    Symbol to prepend in the result
   */
  moneyFormat(value, options = {}) {
    let parsedValue = '';
    try {
      // Parse as float then as string
      parsedValue = `${parseFloat(value)}`;
      // Split
      let [integrer, decimals = '0'] = value.split('.');
      // Add comas
      integrer = integrer.replace(/(?!\b)(\d{3}(?=(\d{3})*\b))/g, ',$1');
      // Force decimals
      decimals = decimals.slice(0, options.decimals || 2).padEnd(options.decimals || 2, '0');
      // Join in value
      parsedValue = `${integrer}.${decimals}`;
    } catch (err) {
      console.error(err);
      // Return 0 if error
      parsedValue = 0;
    }
    // Return parsed value
    return `${options.symbol || ''}${parsedValue}`;
  }

  render() {
    const {
      product, cachedItemBeforeUpdate, onCartItemRemove, onCartItemUpdate, onCartItemDelete, checkout
    } = this.props;
    const thumborSizes = Object.entries(this.state.mainSize).map(value => value[1]);
    const isAnimateOnRemoveOnly = cachedItemBeforeUpdate.position === product.position;
    const imageSrc = getImageSrcByType(product.images, PRIMARY) || '/assets/images/no_product_image.png';
    const { errors } = this.state;
    let checkoutCartItem;

    const REGULAR_VALUES = product.promotions.find(promo => promo.code === 'regular') || {};
    const PROMOTION_VALUES = product.promotions.find(promo => promo.code !== 'regular') || {};
    const WAS_PRICE = parseInt(`${product.wasPrice || 0}`.replace('$', ''), 10);

    const PROMO_TEXT = parseHTML(((product.badges || []).find(badge => badge.type === 'promoBadges') || { descriptions: ['<span></span>'] }).descriptions[0] || '<span></span>');

    const maxWidth = 480;
    const isMobile = (window.screen.width <= maxWidth) ? true : '';

    if (checkout) {
      checkoutCartItem = (
        <div>
          {
            errors.hasError &&
            <div className={`${getAlertClass(errors.hasNoStockError)} alert-box alert`}>
              {
                errors.hasNoStockError ?
                  // work arround to have child message with remove item functionality
                  <span>
                    <FormattedMessage id="cart.update.noStock.startAlertMessage" />
                    {' '}
                    <span className="remove-cta" onClick={(e) => onCartItemRemove(e.target, product.position, isAnimateOnRemoveOnly)}>
                      <FormattedMessage id="cart.update.noStock.removed.cta" />
                    </span>
                    {' '}
                    <FormattedMessage id="cart.update.noStock.endAlertMessage" />
                  </span> :
                  <FormattedMessage
                    id={`cart.update.${product.productModification.statusCode}`}
                    description={`Message for ${product.productModification.statusCode}`}
                    values={{ 0: product.productModification.stock, 1: getRawValue(product.name) }}
                  />
              }
            </div>
          }
          <Wrapper>
            <Delete onClick={(e) => this.delete(e)} />
            <Column>
              <Img
                sizes={['30vw', '20vw', '10vw']}
                thumborSizes={thumborSizes}
                alt=""
                imgSrc={imageSrc}
                cName="lazyload"
                initialSize={100}
                onClick={() => {
                  window.location.href = product.url;
                }}
              />
              <ItemCounter
                onCartItemUpdate={onCartItemUpdate}
                product={product}
                onCartItemDelete={onCartItemDelete}
                hasError={errors.hasError}
              />
            </Column>
            <Column wide>
              <Title>{product.name}</Title>
              {
                WAS_PRICE
                  ? (
                    <Fragment>
                      <RegularPrice>Precio de lista: <s>{product.wasPrice}</s></RegularPrice>
                      {
                        !isMobile &&
                        <OfferPrice count={(product.quantity)}>
                          <span className="icon-discount" />
                          {' '}
                          {PROMOTION_VALUES.adjustedUnitPriceFormatted || this.moneyFormat(product.offerPrice, { symbol: '$' })}
                          {' '}
                          {PROMO_TEXT}
                        </OfferPrice>
                      }
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <RegularPrice count={REGULAR_VALUES.quantity}>Precio de lista: {REGULAR_VALUES.quantity ? product.price : <s>{product.price}</s>}</RegularPrice>
                      {
                        (product.badges || []).length && !isMobile
                          ? (
                            <OfferPrice count={(PROMOTION_VALUES.quantity)}>
                              <span className="icon-discount" />
                              {' '}
                              {PROMOTION_VALUES.adjustedUnitPriceFormatted || this.moneyFormat(product.offerPrice, { symbol: '$' })}
                              {' '}
                              {PROMO_TEXT || ''}
                            </OfferPrice>
                          )
                          : ''
                      }
                    </Fragment>
                  )
              }
              <TotalPrice>{product.totalPrice}</TotalPrice>
            </Column>
          </Wrapper>
          {
            WAS_PRICE && isMobile
            ? (
              <OfferPrice count={(product.quantity)}>
                <span className="icon-discount" />
                {' '}
                {PROMOTION_VALUES.adjustedUnitPriceFormatted || this.moneyFormat(product.offerPrice, { symbol: '$' })}
                {' '}
                {PROMO_TEXT}
              </OfferPrice>
            )
            : (
              <div>
                {
                  (product.badges || []).length && isMobile
                    ? (
                      <OfferPrice count={(PROMOTION_VALUES.quantity)}>
                        <span className="icon-discount" />
                        {' '}
                        {PROMOTION_VALUES.adjustedUnitPriceFormatted || this.moneyFormat(product.offerPrice, { symbol: '$' })}
                        {' '}
                        {PROMO_TEXT || ''}
                      </OfferPrice>
                    )
                    : ''
                }
              </div>
            )
          }
        </div>
      );
    } else {
      checkoutCartItem = (
        <div>
          {
            errors.hasError &&
            <div className={`${getAlertClass(errors.hasNoStockError)} alert-box alert`}>
              {
                errors.hasNoStockError ?
                  // work arround to have child message with remove item functionality
                  <span>
                    <FormattedMessage id="cart.update.noStock.startAlertMessage" />
                    {' '}
                    <span className="remove-cta" onClick={(e) => onCartItemRemove(e.target, product.position, isAnimateOnRemoveOnly)}>
                      <FormattedMessage id="cart.update.noStock.removed.cta" />
                    </span>
                    {' '}
                    <FormattedMessage id="cart.update.noStock.endAlertMessage" />
                  </span> :
                  <FormattedMessage
                    id={`cart.update.${product.productModification.statusCode}`}
                    description={`Message for ${product.productModification.statusCode}`}
                    values={{ 0: product.productModification.stock, 1: getRawValue(product.name) }}
                  />
              }
            </div>
          }
          <Wrapper>
            <Delete onClick={(e) => this.delete(e)} />
            <Column>
              <Img
                sizes={['30vw', '20vw', '10vw']}
                thumborSizes={thumborSizes}
                alt=""
                imgSrc={imageSrc}
                cName="lazyload"
                initialSize={100}
                onClick={() => {
                  window.location.href = product.url;
                }}
              />
              <ItemCounter
                onCartItemUpdate={onCartItemUpdate}
                product={product}
                onCartItemDelete={onCartItemDelete}
                hasError={errors.hasError}
              />
            </Column>
            <Column wide>
              <Title>{product.name}</Title>
              <div className="cart-item__code">
                <FormattedMessage id="articles.articgrid.code" description="code" />
                {' '}
                {product.baseProduct}
              </div>
              {
                WAS_PRICE
                  ? (
                    <Fragment>
                      <RegularPrice><s>{product.wasPrice}</s> <span>Precio de lista.</span></RegularPrice>
                      {
                        !isMobile &&
                        <OfferPrice count={(product.quantity)}>
                          <span className="icon-discount" />
                          {' '}
                          {PROMOTION_VALUES.adjustedUnitPriceFormatted || this.moneyFormat(product.offerPrice, { symbol: '$' })}
                          {' '}
                          {PROMO_TEXT}
                        </OfferPrice>
                      }
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <RegularPrice count={REGULAR_VALUES.quantity}>{REGULAR_VALUES.quantity ? product.price : <s>{product.price}</s>} <span>Precio de lista.</span> </RegularPrice>
                      {
                        (product.badges || []).length && !isMobile
                          ? (
                            <OfferPrice count={(PROMOTION_VALUES.quantity)}>
                              <span className="icon-discount" />
                              {' '}
                              {PROMOTION_VALUES.adjustedUnitPriceFormatted || this.moneyFormat(product.offerPrice, { symbol: '$' })}
                              {' '}
                              {PROMO_TEXT || ''}
                            </OfferPrice>
                          )
                          : ''
                      }
                    </Fragment>
                  )
              }
              <TotalPrice>{product.totalPrice}</TotalPrice>
            </Column>
          </Wrapper>
          {
            WAS_PRICE && isMobile
            ? (
              <OfferPrice count={(product.quantity)}>
                <span className="icon-discount" />
                {' '}
                {PROMOTION_VALUES.adjustedUnitPriceFormatted || this.moneyFormat(product.offerPrice, { symbol: '$' })}
                {' '}
                {PROMO_TEXT}
              </OfferPrice>
            )
            : (
              <div>
                {
                  (product.badges || []).length && isMobile
                    ? (
                      <OfferPrice count={(PROMOTION_VALUES.quantity)}>
                        <span className="icon-discount" />
                        {' '}
                        {PROMOTION_VALUES.adjustedUnitPriceFormatted || this.moneyFormat(product.offerPrice, { symbol: '$' })}
                        {' '}
                        {PROMO_TEXT || ''}
                      </OfferPrice>
                    )
                    : ''
                }
              </div>
            )
          }
        </div>
      );
    }
    return (
      <li className="cart-item-container">
        {checkoutCartItem}
      </li>
    );
  }
}

CartItem.propTypes = {
  product: PropTypes.object.isRequired,
  cachedItemBeforeUpdate: PropTypes.object,
  onDeleteCart: PropTypes.func,
  onCartItemRemove: PropTypes.func,
  onCartItemUpdate: PropTypes.func.isRequired,
  onCartItemDelete: PropTypes.func.isRequired,
  resetErrorFlag: PropTypes.func,
  cartModifications: PropTypes.any,
  checkout: PropTypes.bool,
  totalEntries: PropTypes.number
};

CartItem.defaultProps = {
  showItemCounter: false,
  cachedItemBeforeUpdate: {},
  className: ''
};

const mapDispatchToProps = dispatch => ({
  onDeleteCart() {
    dispatch(cartFinishedUpdate());
    dispatch(deleteCart(<FormattedMessage id="cart.update.error" />));
  }
});

export default connect(undefined, mapDispatchToProps)(CartItem);
