import {
  REQUEST_CART_ITEMS, RECEIVE_CART_ITEMS, RECEIVE_CART_ITEMS_SUCCESSFULLY,
  GET_CART_URL, UPDATE_CART_ITEM_URL, REMOVE_CART_ITEM_URL,
  HIDE_CONFIRMATION_MODAL, RESET_CART_VALIDATION, DELETE_INFO_DELETE_ITEM
} from './cartListConstants';
import { CART_SOURCES } from '../../cartConstants';
import { CACHE_ITEM_BEFORE_UPDATE } from 'app/commonComponents/cartItem/cartItemConstants';
import { PROMO_STATUSES } from '../cartSummary/appliedCoupon/appliedCouponConstants';
import { fetchEmptyCart } from './../emptyCart/emptyCartActions';
import { cartTotalItems, removeCartBanner } from '../../cartUtils';
import { ajaxRequest } from 'app/utils/helpers';
import { cartCurrentlyUpdating, cartFinishedUpdate } from '../../../../commonComponents/cartItem/cartItemActions';

const { baseCartUrl } = window.inlineCartConfiguration;

export const requestCartItems = () => ({
  type: REQUEST_CART_ITEMS
});

export const receiveCartItems = (json = {
  id: '',
  entries: [],
  totalEntries: 0,
  totalItems: 0,
  subTotal: '',
  deliveryMode: {},
  totalDiscounts: '',
  totalPrice: '',
  totalTax: '',
  showTaxes: false,
  appliedVoucher: '',
  cartSource: '',
  appliedOrderPromotions: [],
  appliedProductPromotions: []
}) => ({
  type: RECEIVE_CART_ITEMS,
  ...json,
  totalEntries: cartTotalItems(json),
});

export const applyPromo = () => (
  {
    type: PROMO_STATUSES.PROMO_APPLIED
  }
);

export const cacheItemBeforeUpdate = (product = {}) => (
  {
    type: CACHE_ITEM_BEFORE_UPDATE,
    product
  }
);

export const errorCallback = () => dispatch => {
  dispatch(receiveCartItems());
  dispatch(fetchEmptyCart());
};

const getItemContainer = (targetElement) => (targetElement.classList.contains('cart-item-wrapper') ? targetElement : getItemContainer(targetElement.parentNode));

export const successCallback = (json, cartSource, initialFetchCart) => dispatch => {
  const appliedVoucher = (json.appliedVouchers && json.appliedVouchers[0]) ? json.appliedVouchers[0].voucherCode : '';
  if (appliedVoucher) {
    dispatch(applyPromo());
  }

  dispatch(receiveCartItems({
    ...json,
    appliedVoucher,
    cartSource,
    initialFetchCart
  }));

  dispatch({ type: RECEIVE_CART_ITEMS_SUCCESSFULLY });
};

export const executeAjaxRequest = (params, cartSources, initialFetchCart) => dispatch =>
  ajaxRequest(params.method, params.url, params.data).then(
    (json) => (json.totalItems !== 0 ? dispatch(successCallback(json, cartSources, initialFetchCart)) : dispatch(errorCallback())),
    () => dispatch(errorCallback())
  );

export const hideCarousel = () => (dispatch) => {
  dispatch({
    type: HIDE_CONFIRMATION_MODAL
  });
};

export const fetchCart = (initialFetchCart = false) => (dispatch) => {
  const requestParams = {
    method: 'GET',
    url: `${baseCartUrl}${GET_CART_URL}`,
  };
  dispatch(requestCartItems());
  return dispatch(executeAjaxRequest(requestParams, CART_SOURCES.FETCH_CART, initialFetchCart));
};

export const updateCart = (product, quantity) => (dispatch, getState) => {
  if (getState().isCartFetching) {
    return Promise.resolve();
  }

  const entryNumber = product.position;
  const requestParams = {
    method: 'POST',
    url: `${baseCartUrl}${UPDATE_CART_ITEM_URL}`,
    data: { entryNumber, quantity },
  };

  dispatch(cacheItemBeforeUpdate(product));

  dispatch(requestCartItems());
  return dispatch(executeAjaxRequest(requestParams, CART_SOURCES.UPDATE_CART_ITEM));
};

export const removeCartItem = (entryNumber) => (dispatch, getState) => {
  if (getState().isCartFetching) {
    return null;
  }
  const requestParams = {
    method: 'POST',
    url: `${baseCartUrl}${REMOVE_CART_ITEM_URL}`,
    data: { entryNumber },
  };
  dispatch(cacheItemBeforeUpdate({}));
  dispatch(requestCartItems());
  return dispatch(executeAjaxRequest(requestParams, CART_SOURCES.REMOVE_CART_ITEM));
};

export const onCartItemRemove = () => (dispatch, getState) => {
  const itemRemove = getState().cart.itemRemove;
  const { totalEntries, isAnimateOnly, targetElement, position, checkout } = itemRemove;
  const isLatestItem = (totalEntries === 1 && !isAnimateOnly) || (totalEntries === 0 && isAnimateOnly);
  let animatedContainer = !checkout && isLatestItem ? document.querySelector('.cart.container') : getItemContainer(targetElement);

  const onTransitionend = () => {
    if (animatedContainer.classList.contains('opacity-decrease-animation')) {
      animatedContainer.classList.remove('opacity-decrease-animation');
      animatedContainer.classList.add('height-decrease-animation');
    } else {
      animatedContainer.removeEventListener('transitionend', onTransitionend);
      if (!isAnimateOnly) {
        dispatch(removeCartItem(position));
      } else {
        dispatch({ type: RESET_CART_VALIDATION });
      }

      if (animatedContainer.classList.contains('.cart.container')) {
        removeCartBanner();
      }

      if (checkout && totalEntries === 1) {
        window.location.href = window.location.origin;
      }
    }
  };
  animatedContainer.addEventListener('transitionend', onTransitionend);
  animatedContainer.classList.add('opacity-decrease-animation');
  dispatch({ type: HIDE_CONFIRMATION_MODAL });
  dispatch({ type: DELETE_INFO_DELETE_ITEM });
  dispatch(cartFinishedUpdate());
};

