import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '../../modal/modalWrapper';
import mediaQueries from '../../utils/mediaQueries';
import { topWrapperOffset } from '../../utils/helpers';

class DeliveryOptions extends Component {
  constructor() {
    super();

    this.state = {
      isModalOpen: false,
      topOffset: null
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen
    }), () => {
      if (!mediaQueries.is_large_up()) {
        if (!document.querySelector('html').classList.contains('delivery-options__no-scroll')) {
          this.setState({
            topOffset: window.pageYOffset
          }, () => topWrapperOffset('open', this.state.topOffset, 'delivery-options__no-scroll'));
        } else {
          topWrapperOffset('close', this.state.topOffset, 'delivery-options__no-scroll');
        }
      }
    });
  }

  render() {
    const { isModalOpen } = this.state;
    const { customClass, cmsMessage } = this.props;

    return (
      cmsMessage.summary &&
      <div className={`${customClass}__delivery-options ${!cmsMessage.descriptionContent ? `${customClass}__delivery-options--no-content` : ''}`}>
        <div className={`${customClass}__delivery-options-summary`} dangerouslySetInnerHTML={{ __html: cmsMessage.summary }} />
        {
          cmsMessage.descriptionContent &&
          <button className={`${customClass}__delivery-options-icon`} onClick={this.toggleModal}>
            <i className="icon-info" />
          </button>
        }
        <ModalWrapper
          isModalOpen={isModalOpen}
          modalToggle={this.toggleModal}
          customParentClass="delivery-options__modal"
          titleMsgHTML={cmsMessage.descriptionTitle}
          isHTMLContent
        >
          {cmsMessage.descriptionContent}
        </ModalWrapper>
      </div>
    );
  }
}

DeliveryOptions.propTypes = {
  customClass: PropTypes.string.isRequired,
  cmsMessage: PropTypes.object.isRequired
};

export default DeliveryOptions;
