import React, { Component } from 'react';
import store from './loggedUserInfoStore';
import { fetchUserInfo } from './loggedUserInfoActions';

const loggedUserInfoHOC = (WrappedComponent) =>
  class checkAddress extends Component {
    componentWillMount() {
      if (!store.getState().LoggedUserInfo.isFetching) {
        store.dispatch(fetchUserInfo());
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default loggedUserInfoHOC;
