import { isEmpty } from 'lodash';
import {
  REQUEST_CART_ITEMS,
  RECEIVE_CART_ITEMS,
  RESET_CART_VALIDATION,
  RESET_ERRORS_FLAG,
  HIDE_CONFIRMATION_MODAL,
  SHOW_CONFIRMATION_MODAL,
  SAVE_INFO_DELETE_ITEM,
  DELETE_INFO_DELETE_ITEM,
} from './cartListConstants';
import {
  REQUEST_ADD_CART_ITEM,
  RECEIVE_ADD_CART_ITEM,
  STATUS_CODES
} from 'app/commonComponents/addToBag/addToBagConstants';

import {
  SET_CHECKOUT_CART_DATA
} from 'app/checkout/checkoutConstants';

export const isCartFetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST_ADD_CART_ITEM:
    case REQUEST_CART_ITEMS:
      return true;
    case RECEIVE_CART_ITEMS:
    case RECEIVE_ADD_CART_ITEM:
      return false;
    default:
      return state;
  }
};

export const hasErrors = (state, action) => {
  switch (action.type) {
    case RESET_ERRORS_FLAG:
      return action.flag;
    default:
      return false;
  }
};

const matchPromotionPerEntry = (promotions, entries) => {
  const matchedPromoEntries = [...entries];
  if (!isEmpty(promotions)) {
    promotions.forEach(promotion => {
      if (!isEmpty(promotion.consumedEntries)) {
        promotion.consumedEntries.forEach(promoEntry => {
          matchedPromoEntries[promoEntry.orderEntryNumber].appliedPromo = promotion.description;
          matchedPromoEntries[promoEntry.orderEntryNumber].adjustedUnitPriceFormatted = promoEntry.adjustedUnitPriceFormatted;
        });
      }
    });
  }
  return matchedPromoEntries;
};

export const cart = (state = {
  id: '',
  entries: [],
  cartModifications: [],
  totalItems: 0,
  subTotal: '',
  deliveryMode: {},
  totalDiscounts: '',
  totalPrice: '',
  appliedVoucher: '',
  cartSource: '',
  appliedProductPromotions: [],
  appliedOrderPromotions: [],
  isModalOpen: false,
  onModalCancel: () => {},
  itemRemove: {}
}, action) => {
  switch (action.type) {
    case SET_CHECKOUT_CART_DATA:
      return {
        ...state,
        ...action.cartData,
        isZeroValuePayment: action.isZeroValuePayment,
        wasZeroValuePayment: state.isZeroValuePayment,
        isCheckoutCartFetching: false,
      };
    case RECEIVE_CART_ITEMS:
      return Object.assign({}, state, {
        ...action,
        entries: matchPromotionPerEntry(action.appliedProductPromotions, action.entries),
        isModalOpen: state.isModalOpen,
      });
    case RESET_CART_VALIDATION:
      return {
        ...state,
        cartModifications: []
      };
    case STATUS_CODES.ADDED_TO_BAG:
      return {
        ...state,
        totalItems: state.totalItems + action.data.quantity
      };

    case HIDE_CONFIRMATION_MODAL:
      return {
        ...state,
        isModalOpen: false,
      };

    case SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        isModalOpen: true,
        onModalCancel: action.onModalCancel
      };

    case SAVE_INFO_DELETE_ITEM:
      return {
        ...state,
        itemRemove: action.item
      };

    case DELETE_INFO_DELETE_ITEM:
      return {
        ...state,
        itemRemove: {}
      };
    default:
      return state;
  }
};
