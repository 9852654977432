import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AccountNameHeader from './components/accountNameHeader';
import AccountNameHeaderMobile from './components/accountNameHeaderMobile';
import AccountNameTitle from './components/accountNameTitle';

const AccountNameApp = (props) => {
  const {
    nameType, accountName, accountIsLogged, isFetching, urlImageProfile
  } = props;

  if (isFetching) {
    return null;
  }

  if (nameType === 'desktop') {
    return (
      <AccountNameHeader accountName={accountName} accountIsLogged={accountIsLogged} urlImageProfile={urlImageProfile} />
    );
  } else if (nameType === 'mobile') {
    return (
      <AccountNameHeaderMobile accountName={accountName} accountIsLogged={accountIsLogged} urlImageProfile={urlImageProfile} />
    );
  }

  return (
    <AccountNameTitle accountName={accountName} />
  );
};

AccountNameApp.propTypes = {
  nameType: PropTypes.string,
  accountName: PropTypes.string,
  accountIsLogged: PropTypes.bool,
  isFetching: PropTypes.bool,
  urlImageProfile: PropTypes.string
};

const mapStateToProps = ({ LoggedUserInfo }) => ({
  accountName: LoggedUserInfo.accountName,
  accountIsLogged: LoggedUserInfo.accountIsLogged,
  isFetching: LoggedUserInfo.isFetching,
  urlImageProfile: LoggedUserInfo.urlImageProfile
});

export default connect(mapStateToProps)(AccountNameApp);
