import { isEmpty } from 'lodash';
import store from './loggedUserInfoStore';
import {
  REQUEST_USER_INFO,
  RECEIVE_USER_INFO,
  UPDATE_ACCOUNT_NAME,
  UPDATE_ACCOUNT_LOGGED_STATUS,
  UPDATE_CSAGENT_INFO,
  UPDATE_CSAGENT_LOGGED_STATUS
} from './loggedUserInfoConstants';
import { ajaxRequest } from 'app/utils/helpers';

const { loggedUserInfoUrl } = window.inlineGlobalConfig;

export const requestUserInfo = () => (dispatch) =>
  dispatch({ type: REQUEST_USER_INFO });

export const receiveUserInfo = () => (dispatch) =>
  dispatch({ type: RECEIVE_USER_INFO });

export const updateAccountName = (data) => (dispatch) =>
  dispatch({
    type: UPDATE_ACCOUNT_NAME,
    accountName: `${data.firstName} ${data.lastName}`,
    urlImageProfile: data.profilePicture,
  });

export const updateCSAgentUserInfo = (data) => (dispatch) =>
  dispatch({
    type: UPDATE_CSAGENT_INFO,
    csAgentUsername: data.username,
    csAgentCartId: data.cartId
  });

export const updateAccountLoggedStatus = (status, data) => (dispatch) =>
  dispatch({
    type: UPDATE_ACCOUNT_LOGGED_STATUS,
    accountIsLogged: status,
    encodedId: data['userInfo/index'].encodedId,
    customerInfo: data['account/accountInfo']
  });

export const updateCSAgentLoggedStatus = (status) => (dispatch) =>
  dispatch({
    type: UPDATE_CSAGENT_LOGGED_STATUS,
    csAgentIsLogged: status
  });

export const fetchUserInfo = () => (dispatch) => {
  if (!store.getState().LoggedUserInfo.isFetching) {
    dispatch(requestUserInfo());

    ajaxRequest('GET', `${loggedUserInfoUrl}&_=${Date.now()}`)
      .then((data) => {
        if (!isEmpty(data['account/accountInfo']) && !isEmpty(data['userInfo/index'].username)) {
          dispatch(updateAccountLoggedStatus(true, data));
          dispatch(updateAccountName(data['account/accountInfo']));
        } else {
          dispatch(updateAccountLoggedStatus(false, data));
        }

        if (!isEmpty(data['userInfo/csAgentInfo'])) {
          dispatch(updateCSAgentLoggedStatus(true));
          dispatch(updateCSAgentUserInfo(data['userInfo/csAgentInfo']));
        } else {
          dispatch(updateCSAgentLoggedStatus(false));
        }

        dispatch(receiveUserInfo());
      });
  }
};
