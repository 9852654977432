import { CACHE_ITEM_BEFORE_UPDATE, CART_CURRENTLY_UPDATING, CART_FINISHED_UPDATE } from 'app/commonComponents/cartItem/cartItemConstants';

const initialState = { isCartUpdating: false };

export const cachedItemBeforeUpdate = (state = initialState, action) => {
  switch (action.type) {
    case CACHE_ITEM_BEFORE_UPDATE:
      return {
        ...state,
        ...action.product
      };
    case CART_CURRENTLY_UPDATING:
      return {
        ...state,
        isCartUpdating: true,
      };
    case CART_FINISHED_UPDATE:
      return {
        ...state,
        isCartUpdating: false,
      };
    default:
      return state;
  }
};
