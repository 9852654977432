import { GET_CURRENT_STORE } from './paymentIconsConstants';
import { ajaxRequest } from 'app/utils/helpers';

const { locale, baseStoreUrl } = window.inlineGlobalConfig;

const saveCurrentStore = (currentStore) => ({
  type: GET_CURRENT_STORE,
  currentStore
});

const findCurrentStore = (stores) =>
  stores.filter(store => store.uid === locale.country.toUpperCase())[0];

export const fetchCurrentStore = () => (dispatch) => (
  ajaxRequest('GET', baseStoreUrl).then(
    json => dispatch(saveCurrentStore(findCurrentStore(json.stores))),
    () => dispatch(saveCurrentStore({ currentStore: {} }))
  )
);
